import React from 'react'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import { Heading } from 'components/shared/typography'
import Container from 'components/shared/container'
import Breadcrumbs from 'components/shared/breadcrumbs'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { PolitykaPrywatnosciPageQuery } from 'types/graphql'

const StyledWrapper = styled.div`
  margin-bottom: 80px;

  ${media.lg.min} {
    max-width: 80ch;
    margin: 35px auto 100px auto;
  }
`

const Content = styled.div`
  p {
    line-height: 22px;
    margin-bottom: 10px;
  }

  p {
    font-size: 19px;
    line-height: 27px;
  }
`

const PolitykaPrywatnosci: React.FC<
  PageProps<PolitykaPrywatnosciPageQuery>
> = ({ data }) => {
  const PAGE = data?.wpPage!
  const PAGE_SEO = data?.wpPage?.seo!

  const { lg } = useBreakpoint()

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Breadcrumbs spacing crumbs={[{ name: 'Polityka prywatności' }]} />

      <Container>
        <StyledWrapper>
          <Heading
            size={lg ? 34 : 24}
            margin={lg ? '60px' : '40px'}
            sizeDiff={0}
          >
            Polityka prywatności
          </Heading>
          <Content dangerouslySetInnerHTML={{ __html: PAGE?.content! }} />
        </StyledWrapper>
      </Container>
    </Layout>
  )
}

export default PolitykaPrywatnosci

export const query = graphql`
  query PolitykaPrywatnosciPage {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      seo {
        ...WpSEO
      }
      content
    }
  }
`
